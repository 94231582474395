
import { defineComponent } from 'vue';
import HeaderNavigation from './components/HeaderNavigation.vue'


export default defineComponent({
  name: 'App',
  components: {
    HeaderNavigation
  },
  computed: {
    usePageWrapper(): boolean {
      return this.$route.name !== 'login'
    }
  },
  mounted() {
    let mediaQuery1 = window.matchMedia('(max-width: 999px)');
    this.$store.commit('setWindowWidthLessThan1000px', mediaQuery1.matches)
    window.addEventListener('resize', () => {
      this.$store.commit('setWindowWidthLessThan1000px', mediaQuery1.matches)
    });

    let mediaQuery2 = window.matchMedia('(max-width: 599px)');
    this.$store.commit('setWindowWidthLessThan600px', mediaQuery2.matches)
    window.addEventListener('resize', () => {
      this.$store.commit('setWindowWidthLessThan600px', mediaQuery2.matches)
    });

  }
});
