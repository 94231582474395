
import { defineComponent } from 'vue';
import { $api } from '@/common/apiService'
import { newTrip } from '@/common/utils';
import { NewTrip } from '@/Models/NewTrip';


export default defineComponent ({
    name: "CreateTripForm",
    components: {
    },
    props: {
        showForm: {
            type: Boolean,
            required: true
        }
    },
    emits: ['update:showForm', 'submitted'],
    computed: {
        show: {
            get() {
                return this.showForm
            },
            set(value: boolean) {
                this.$emit('update:showForm', value)
            }
        }
    },
    data() {
        return {
            newTrip: newTrip() as NewTrip,
            loading: false as Boolean,
            error: "" as String,
            yearOptions: [] as number[]
        }
    },
    watch: {
        showForm: function() {
            this.error = ""
            this.newTrip = newTrip()
        }
    },
    mounted() {
        this.newTrip = newTrip()
        const maxYear = new Date().getFullYear() + 5
        const startYear = 2000
        this.yearOptions = Array.from({length: maxYear - startYear}, (value, index) => startYear + index)
    },
    methods: {
        async createTrip() {
            this.error = ""
            this.loading = true
            const trip = await $api.trips.create(this.newTrip)
            this.loading = false
            if (trip) {
                this.show = false
                this.$emit('submitted')            }
            else {
                this.error = "Der opstod en fejl. Prøv igen"
            }
        }
    }
});
