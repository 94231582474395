
import { defineComponent } from 'vue';
import { $api } from '../common/apiService';
import texts from '../assets/Text.json'

export default defineComponent ({
    name: "LoginCard",
    components: {
    },
    data () {
        return {
            username: "",
            password: "",
            errorMessage: "",
            emptyFields: false,
            texts: texts
        }
    },
    methods: {
        async logIn() {
            this.errorMessage = ""
            this.emptyFields = false
            if (!this.validateLogin()) {
                return
            }
            const session = await $api.auth.login(this.username, this.password)
            if (session === null || session?.jwt === null) {
                this.errorMessage = texts.Login.LoginFailed
                return
            }
            await this.$store.dispatch('setSession', session)
            const token = await $api.token.get()
            if (token) {
                await this.$store.dispatch('setSasToken', token)
            }
            this.$emit('loggedIn')
        },
        validateLogin() {
            if (this.username === "" || this.password === "") {
                this.emptyFields = true
                return false
            }
            else return true
        }
    }
});

