import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_card = _resolveComponent("b-card")!

  return (_ctx.divMode)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(_ctx.style)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 4))
    : (_openBlock(), _createBlock(_component_b_card, {
        key: 1,
        style: _normalizeStyle(_ctx.style)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["style"]))
}