import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a245aa26"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_img = _resolveComponent("b-img")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form_select_option = _resolveComponent("b-form-select-option")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_offcanvas = _resolveComponent("b-offcanvas")!

  return (_openBlock(), _createBlock(_component_b_offcanvas, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
    title: `Ret billedeoplysninger`
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_b_spinner, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_b_img, {
              class: "image-preview",
              src: _ctx.imageUri
            }, null, 8, ["src"]),
            _createVNode(_component_b_form, { onSubmit: _ctx.updateImage }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_group, { label: "Beskrivelse" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_textarea, {
                      modelValue: _ctx.editableImage.description,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editableImage.description) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_form_group, { label: "Uploadet af" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_select, {
                      modelValue: _ctx.editableImage.uploadedBy,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editableImage.uploadedBy) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                          return (_openBlock(), _createBlock(_component_b_form_select_option, {
                            key: user.userName,
                            value: user.userName
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(user.userName), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                (_ctx.error)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.error), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_b_button, {
                  class: "submit-button",
                  size: "lg",
                  type: "submit",
                  variant: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Opdatér")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ], 64))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}