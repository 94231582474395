import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6642ae8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_b_spinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_b_button, {
          onClick: _ctx.generateThumbnails,
          variant: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Generér thumbnails")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("p", null, _toDisplayString(_ctx.thumbnailMessage), 1)
      ]))
}