
import { PropType, defineComponent } from 'vue';
import { $api } from '@/common/apiService'
import { User } from '@/Models/User';
import { emptyUser } from '@/common/utils';


export default defineComponent ({
    name: "EditUserForm",
    components: {
    },
    props: {
        showForm: {
            type: Boolean,
            required: true
        },
        user: {
            type: Object as PropType<User>,
            required: true
        }
    },
    emits: ['update:showForm', 'submitted'],
    computed: {
        show: {
            get() {
                return this.showForm
            },
            set(value: boolean) {
                this.$emit('update:showForm', value)
            }
        }
    },
    data() {
        return {
            editableUser: emptyUser() as User,
            roleOptions: ["Admin", "User"],
            loading: false as Boolean,
            error: "" as String
        }
    },
    watch: {
        showForm: function() {
            this.error = ""
            this.editableUser = JSON.parse(JSON.stringify(this.user))    
        }
    },
    mounted() {
        this.editableUser = JSON.parse(JSON.stringify(this.user))
    },
    methods: {
        async updateUser() {
            this.error = ""
            this.loading = true
            const newUser = await $api.users.update(this.editableUser)
            this.loading = false
            if (newUser) {
                this.show = false
                this.$emit('submitted')
            }
            else {
                this.error = "Der opstod en fejl..."
            }
        }
    }
});
