import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_GMapMap, {
        key: 0,
        center: _ctx.center,
        zoom: 8,
        "map-type-id": "terrain",
        style: {"width":"100%","height":"100%"}
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_GMapMarker, {
            key: 1,
            position: _ctx.marker,
            clickable: true,
            draggable: true
          }, null, 8, ["position"]))
        ]),
        _: 1
      }, 8, ["center"]))
    : _createCommentVNode("", true)
}