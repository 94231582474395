import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "d-grid gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createBlock(_component_b_card, {
    title: _ctx.trip?.year.toString(),
    subtitle: _ctx.trip?.location,
    "img-src": _ctx.image,
    style: _normalizeStyle(_ctx.injectedCardStyle),
    "img-alt": "Image",
    "img-top": ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.trip != null)
          ? (_openBlock(), _createBlock(_component_b_button, {
              key: 0,
              href: "#",
              variant: "primary",
              to: `/trips/${_ctx.trip?.year}`
            }, {
              default: _withCtx(() => [
                _createTextVNode("Se detaljer")
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "subtitle", "img-src", "style"]))
}