
import { defineComponent } from 'vue';
import LoginCard from '@/components/LoginCard.vue'
import ChangePasswordCard from '@/components/ChangePasswordCard.vue'


export default defineComponent ({
    name: "LoginPage",
    components: {
        LoginCard,
        ChangePasswordCard
    },
    data () {
        return {
            loginState: "login" as "login" | "changePassword"
        }
    },
    methods: {
        afterLogin() {
            const user = this.$store.getters.getSession.user
            if (user.resetPassword === true) {
                this.showChangePassword()
            }
            else {
                this.goToMain()
            }
        },
        goToMain() {
            this.$router.push('/')
        },
        showChangePassword() {
            this.loginState = "changePassword"
        }
    }
});

