
import { Trip } from '@/Models/Trip';
import GoogleMap from '@/components/GoogleMap.vue'
import CalendarCard from '@/components/CalendarCard.vue';
import ContentCard from '@/components/ContentCard.vue'
import ImageUploadForm from '@/components/ImageUploadForm.vue';
import ImageCarousel from '@/components/ImageCarousel.vue';
import { Image } from '@/Models/Image';
import { $api } from '@/common/apiService';
import { defineComponent } from 'vue';


export default defineComponent ({
    name: "TripPage",
    components: {
        GoogleMap,
        CalendarCard,
        ContentCard,
        ImageUploadForm,
        ImageCarousel
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            token: null as string | null,
            trip: null as Trip | null,
            coverUri: "" as string,
            locationImageUri: "" as string,
            gallery: [] as Image[] | null,
            loading: true as boolean,
            showUploadImage: false as boolean,
            showImageCarousel: false as boolean,
            selectedImage: -1 as number
        }
    },
    async mounted() {
        this.loading = true
        this.coverUri = require('@/assets/Loading_icon.gif')
        this.locationImageUri = require('@/assets/Loading_icon.gif')
        this.token = this.$store.getters.getSasToken
        this.trip = await $api.trips.get(Number.parseInt(this.id));

        if (this.trip?.coverImageId) {
            const coverImage = await $api.images.get(this.trip?.coverImageId)
            if (coverImage) {
                this.coverUri = coverImage.uri + '?' + this.token;
            }
        }
        if (this.trip?.locationImageId) {
            const locationImage = await $api.images.get(this.trip?.locationImageId)
            if (locationImage) {
                this.locationImageUri = locationImage.uri + '?' + this.token;
            }
        }
        await this.fetchGallery()
        this.loading = false
    },
    methods: {
        async fetchGallery() {
            const images = await $api.images.fetch(this.trip?.year);
            if (images) {
                this.gallery = images.filter(image =>
                    image.id !== this.trip?.coverImageId &&
                    image.id !== this.trip?.locationImageId
                    )
            }
        },
        showGalleryCarousel(index: number) {
            if (this.gallery && this.gallery[index]) {
                this.selectedImage = index
                this.showImageCarousel = true
            }
        }
    }

});
