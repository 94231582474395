
import { PropType, defineComponent } from 'vue';
import { $api } from '@/common/apiService'
import { emptyTrip } from '@/common/utils';
import { Trip } from '@/Models/Trip';
import { Image } from '@/Models/Image';
import SelectImageModal from './SelectImageModal.vue';

export default defineComponent ({
    name: "EditTripForm",
    components: {
        SelectImageModal
    },
    props: {
        showForm: {
            type: Boolean,
            required: true
        },
        trip: {
            type: Object as PropType<Trip>,
            required: true
        }

    },
    emits: ['update:showForm', 'submitted'],
    computed: {
        show: {
            get() {
                return this.showForm
            },
            set(value: boolean) {
                this.$emit('update:showForm', value)
            }
        }
    },
    data() {
        return {
            editableTrip: emptyTrip() as Trip,
            images: [] as Image[],
            coverImageUri: "",
            locationImageUri: "",
            token: "",
            loading: false as Boolean,
            error: "" as String,
            showChangeCoverImageModal: false,
            showChangeLocationImageModal: false,
        }
    },
    watch: {
        showForm: async function() {
            this.error = ""
            await this.loadEditableTrip()    
            await this.loadImages()
        }
    },
    async mounted() {
        this.token = this.$store.getters.getSasToken
        await this.loadEditableTrip()
    },
    methods: {
        async loadImages() {
            const tripImages = await $api.images.fetch(this.trip.year)
            if (tripImages) {
                this.images = tripImages
            }
        },
        async loadEditableTrip() {
            this.editableTrip = JSON.parse(JSON.stringify(this.trip))
            await this.updatePreviews()
        },
        async updatePreviews() {
            if (this.editableTrip.coverImageId) {
            this.coverImageUri = await this.getImageUri(this.editableTrip.coverImageId)
            }
            if (this.editableTrip.locationImageId) {
                this.locationImageUri = await this.getImageUri(this.editableTrip.locationImageId)
            }
        },
        async updateTrip() {
            this.error = ""
            this.loading = true
            const newTrip = await $api.trips.update(this.editableTrip)
            this.loading = false
            if (newTrip) {
                this.show = false
                this.$emit('submitted')
            }
            else {
                this.error = "Der opstod en fejl..."
            }
        },
        async getImageUri(id: number) {
            const image = await $api.images.get(id)
            if (image) {
                return image.uri
            }
            else return ""
        },
        changeCoverImage() {
            this.showChangeCoverImageModal = true
        },
        changeLocationImage() {
            this.showChangeLocationImageModal = true
        },
        async setCoverImage(image: Image) {
            this.editableTrip.coverImageId = image.id
            await this.updatePreviews()
        },
        async setLocationImage(image: Image) {
            this.editableTrip.locationImageId = image.id
            await this.updatePreviews()
        }
    }
});
