import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19cfda44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trip-table-content" }
const _hoisted_2 = {
  key: 0,
  class: "error-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_create_trip_form = _resolveComponent("create-trip-form")!
  const _component_edit_trip_form = _resolveComponent("edit-trip-form")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_button, {
      class: "mb-2",
      variant: "success",
      onClick: _ctx.createTrip
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Opret Tur ")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_b_table, {
      striped: "",
      hover: "",
      busy: _ctx.loading,
      items: _ctx.trips,
      fields: _ctx.tripFields
    }, {
      "cell(actions)": _withCtx((row) => [
        _createVNode(_component_b_button, {
          variant: "primary",
          size: "sm",
          onClick: ($event: any) => (_ctx.editTrip(row)),
          class: "m-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Ret ")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_b_button, {
          variant: "danger",
          size: "sm",
          onClick: ($event: any) => (_ctx.confirmDeleteTrip(row)),
          class: "m-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" X ")
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["busy", "items", "fields"]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_create_trip_form, {
      "show-form": _ctx.showCreateTripModal,
      "onUpdate:showForm": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showCreateTripModal) = $event)),
      onSubmitted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('refresh')))
    }, null, 8, ["show-form"]),
    _createVNode(_component_edit_trip_form, {
      "show-form": _ctx.showEditTripModal,
      "onUpdate:showForm": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showEditTripModal) = $event)),
      trip: _ctx.changedTrip,
      onSubmitted: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('refresh')))
    }, null, 8, ["show-form", "trip"]),
    _createVNode(_component_confirm_modal, {
      "show-modal": _ctx.showConfirmDeleteModal,
      "onUpdate:showModal": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showConfirmDeleteModal) = $event)),
      title: 'Slet tur for ' + _ctx.tripToBeDeleted.year,
      description: `Er du sikker på at du vil slette turen for ${_ctx.tripToBeDeleted.year}`,
      onConfirmed: _ctx.deleteTrip
    }, null, 8, ["show-modal", "title", "description", "onConfirmed"])
  ]))
}