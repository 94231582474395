
import { defineComponent } from 'vue';
import { $api } from '@/common/apiService'
import { User } from '@/Models/User';
import { emptyUser } from '@/common/utils';


export default defineComponent ({
    name: "CreateUserForm",
    components: {
    },
    props: {
        showForm: {
            type: Boolean,
            required: true
        }
    },
    emits: ['update:showForm', 'submitted'],
    computed: {
        show: {
            get() {
                return this.showForm
            },
            set(value: boolean) {
                this.$emit('update:showForm', value)
            }
        }
    },
    data() {
        return {
            newUser: emptyUser() as User,
            roleOptions: ["Admin", "User"],
            userCreated: false as Boolean,
            loading: false as Boolean,
            error: "" as String,
            initialPassword: "" as String | null
        }
    },
    watch: {
        showForm: function() {
            this.error = ""
            this.newUser = emptyUser()
            this.userCreated = false
        }
    },
    mounted() {
        this.newUser = emptyUser()
    },
    methods: {
        async createUser() {
            this.error = ""
            this.loading = true
            this.initialPassword = await $api.users.create(this.newUser)
            this.loading = false
            if (this.initialPassword) {
                this.userCreated = true
            }
            else {
                this.error = "Der opstod en fejl..."
            }
        },
        async close() {
            this.show = false
            this.$emit('submitted')
        }
    }
});
