import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ceda84b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-table-content" }
const _hoisted_2 = {
  key: 0,
  class: "error-text"
}
const _hoisted_3 = { class: "gallery-display-box" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "image-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_edit_image_form = _resolveComponent("edit-image-form")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_b_card, { "no-body": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_b_tabs, { card: "" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (year) => {
              return (_openBlock(), _createBlock(_component_b_tab, {
                title: year.toString(),
                key: year
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imagesByYear(year), (image) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: image.id,
                        class: "image"
                      }, [
                        _createElementVNode("img", {
                          src: (image.thumbUri ?? image.uri) + '?' + _ctx.token ?? require('@/assets/Loading_icon.gif')
                        }, null, 8, _hoisted_4),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_b_button, {
                            onClick: ($event: any) => (_ctx.editImage(image))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Ret")
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_b_button, {
                            onClick: ($event: any) => (_ctx.confirmDeleteImage(image)),
                            variant: "danger"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Slet")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 2
              }, 1032, ["title"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_edit_image_form, {
      "show-form": _ctx.showEditImageModal,
      "onUpdate:showForm": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showEditImageModal) = $event)),
      image: _ctx.changedImage,
      users: _ctx.users,
      onSubmitted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('refresh')))
    }, null, 8, ["show-form", "image", "users"]),
    _createVNode(_component_confirm_modal, {
      "show-modal": _ctx.showConfirmDeleteModal,
      "onUpdate:showModal": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showConfirmDeleteModal) = $event)),
      title: 'Slet billede',
      description: `Er du sikker på at du vil slette dette billede fra ${_ctx.imageToBeDeleted.year}`,
      onConfirmed: _ctx.deleteImage
    }, null, 8, ["show-modal", "description", "onConfirmed"])
  ]))
}