import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d8c1426"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "form-content"
}
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_offcanvas = _resolveComponent("b-offcanvas")!

  return (_openBlock(), _createBlock(_component_b_offcanvas, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.show) = $event)),
    title: `Opret Tur`
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_b_spinner, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_b_form, { onSubmit: _ctx.createTrip }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_group, { label: "År" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_select, {
                      modelValue: _ctx.newTrip.year,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newTrip.year) = $event)),
                      options: _ctx.yearOptions
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_form_group, { label: "Lokation" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      modelValue: _ctx.newTrip.location,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newTrip.location) = $event)),
                      text: "text"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_form_group, { label: "Adresse" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      modelValue: _ctx.newTrip.address,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newTrip.address) = $event)),
                      text: "text"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_form_group, { label: "Beskrivelse" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_textarea, {
                      modelValue: _ctx.newTrip.description,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newTrip.description) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                (_ctx.error)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.error), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_b_button, {
                  class: "submit-button",
                  size: "lg",
                  type: "submit",
                  variant: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Opret")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ]))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}