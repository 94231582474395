import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    "model-value": _ctx.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    title: _ctx.title,
    "ok-title": _ctx.confirmText ?? 'Bekræft',
    "cancel-title": _ctx.cancelText ?? 'Fortryd',
    onCancel: _ctx.cancel,
    onOk: _ctx.confirm
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
    ]),
    _: 1
  }, 8, ["model-value", "title", "ok-title", "cancel-title", "onCancel", "onOk"]))
}