import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e824b7f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gallery-display-box" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    "model-value": _ctx.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
          return (_openBlock(), _createElementBlock("div", {
            key: image.id,
            class: "image",
            onClick: ($event: any) => (_ctx.select(image))
          }, [
            _createElementVNode("img", {
              src: (image.thumbUri ?? image.uri) + '?' + _ctx.token ?? require('@/assets/Loading_icon.gif')
            }, null, 8, _hoisted_3)
          ], 8, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["model-value", "title"]))
}