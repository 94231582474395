
import { PropType, defineComponent } from 'vue'
import { Image } from '@/Models/Image';

export default defineComponent({
    name: 'SelectImageModal',
    components: {
    },
    props: {
        showModal: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        images: {
            type: Object as PropType<Image[]>,
            required: true
        }
    },
    emits: ['update:showModal', 'selected'],
    computed: {
        show: {
            get() {
                return this.showModal
            },
            set(value: boolean) {
                this.$emit('update:showModal', value)
            }
        }
    },
    data() {
        return {
            token: ""
        }
    },
    mounted() {
        this.token = this.$store.getters.getSasToken
    },
    methods: {
        select(image: Image) {
            this.show = false
            this.$emit("selected", image)
        }
    }

});
