
import { defineComponent } from 'vue';
import { $api } from '@/common/apiService'


export default defineComponent ({
    name: "ImageUploadForm",
    components: {
    },
    props: {
        showForm: {
            type: Boolean,
            required: true
        },
        year: {
            type: Number,
            required: true
        }
    },
    emits: ['update:showForm', 'submitted'],
    computed: {
        show: {
            get() {
                return this.showForm
            },
            set(value: boolean) {
                this.$emit('update:showForm', value)
                if (value === false) {
                    this.imageFile = null
                    this.description = ""
                    this.error = ""
                }
            }
        }
    },
    data() {
        return {
            imageFile: null as File | null,
            description: "" as string,
            loading: false as Boolean,
            error: "" as string,
            fileInputKey: 0 as number
        }
    },
    methods: {
        setUploadedImage(payload: Event) {
            let input = payload.target as HTMLInputElement
            this.imageFile = input.files?.item(0) as File
        },
        getPreview() {
            if (this.imageFile) {
                return URL.createObjectURL(this.imageFile)
            }
            else {
                return ""
            }
        },
        async submitImage() {
            this.error = ""
            if (this.imageFile) {
                this.loading = true
                const image = await $api.images.upload(this.imageFile, this.year, this.description)
                this.loading = false
                if (image) {
                    this.show = false
                    this.$emit('submitted')
                }
                else {
                    this.error = "Der opstod en fejl under upload..."
                }
                this.imageFile = null
            }
            else {
                this.error = "Der er ikke valgt et billede"
            }
        }
    }
});
