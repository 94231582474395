
import { defineComponent } from 'vue';
import { $api } from '@/common/apiService'


export default defineComponent ({
    name: "AdminUtilities",
    data() {
        return {
            loading: false as Boolean,
            thumbnailMessage: "" as String,
        }
    },
    methods: {
        async generateThumbnails() {
            this.loading = true;
            this.thumbnailMessage = await $api.images.generateThumbnails();
            this.loading = false;
        }
    }
});
