
import { User } from '@/Models/User';
import { PropType, defineComponent } from 'vue';
import { $api } from '@/common/apiService'
import type { RowType } from '@/common/types'
import { TableItem } from 'bootstrap-vue-next';
import EditUserForm from './EditUserForm.vue';
import CreateUserForm from './CreateUserForm.vue';
import { emptyUser } from '@/common/utils';
import ConfirmModal from '../ConfirmModal.vue';

export default defineComponent ({
    name: "UserTable",
    components: {
        EditUserForm,
        CreateUserForm,
        ConfirmModal
    },
    props: {
        users: {
            type: Object as PropType<TableItem[]>,
            required: true
        },
        loadingUsers: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        loading: {
            get(): boolean {
                return this.loadingUsers
            },
            set(value: boolean) {
                this.$emit('update:loadingUsers', value)
            }
        }
    },
    data() {
        return {
            error: "",
            showNewPasswordModal: false,
            newPassword: "",
            showEditUserForm: false,
            showCreateUserForm: false,
            showConfirmDeleteModal: false,
            changedUser: emptyUser() as User,
            userToBeDeleted: emptyUser() as User,
            userFields: [
                {
                    key: 'userName',
                    label: 'Brugernavn'
                },
                {
                    key: 'name',
                    label: 'Navn'
                },
                {
                    key: 'role',
                    label: 'Rolle'
                },
                {
                    key: 'resetPassword',
                    label: 'Anmod om nyt kodeord'
                },
                {
                    key: 'actions',
                    label: 'Handlinger'
                }
            ]
        }
    },
    methods: {
        async resetPassword(row: RowType) {
            this.error = ""
            this.loading = true
            this.changedUser = row.item as unknown as User
            const response = await $api.users.resetPassword(this.changedUser.userName)
            if (response === null) {
                this.error = "Der skete en fejl på serveren. Prøv igen."
                this.loading = false
            }
            else {
                this.newPassword = response
                this.$emit('refresh')
                this.showNewPasswordModal = true
            }
        },
        editUser(row: RowType) {
            this.error = ""
            this.changedUser = row.item as unknown as User
            this.showEditUserForm = true
        },
        confirmDeleteUser(row: RowType) {
            this.userToBeDeleted = row.item as unknown as User
            this.showConfirmDeleteModal = true
        },
        async deleteUser() {
            this.error = ""
            this.loading = true
            const response = await $api.users.delete(this.userToBeDeleted)
            if (response === null) {
                this.error = "Der skete en fejl på serveren. Prøv igen."
                this.loading = false
            }
            else {
                this.$emit('refresh')
            }
        },
        createUser() {
            this.error = ""
            this.showCreateUserForm = true
        }
    },
});

