
import { PropType, defineComponent } from 'vue';
import { Image } from '@/Models/Image';

export default defineComponent ({
    name: "ImageCarousel",
    components: {
    },
    props: {
        images: {
            type: Object as PropType<Image[]>,
            required: true
        },
        selectedIndex: {
            type: Number,
            required: true
        },
        show: {
            type: Boolean,
            required: true
        }
    },
    emits: ['update:show', 'update:selectedIndex'],
    computed: {
        showCarousel: {
            get() {
                return this.show
            },
            set(value: boolean) {
                this.$emit('update:show', value)
            }
        },
        index: {
            get() {
                return this.selectedIndex
            },
            set(value: number) {
                this.$emit('update:selectedIndex', value)
            }
        }
    },
    data() {
        return {
            token: "",
            first: false,
            last: false
        }
    },
    watch: {
        show: function() {
            this.first = this.index === 0
            this.last = this.index === this.images.length - 1
        }
    },
    mounted() {
        this.token = this.$store.getters.getSasToken
    },
    methods: {
        incrementIndex() {
            if (this.index < this.images.length - 1) {
                if ( this.index == this.images.length - 2) {
                    this.last = true;
                }
                this.index = this.index + 1
                this.first = false;
            }
        },
        decrementIndex() {
            if (this.index > 0) {
                if (this.index == 1) {
                    this.first = true;
                }

                this.index = this.index - 1
                this.last = false;
            }
        }
    }
});
