
import { PropType, defineComponent } from 'vue';
import { Image } from '@/Models/Image';
import { emptyImage } from '@/common/utils';
import { $api } from '@/common/apiService';
import { User } from '@/Models/User';


export default defineComponent ({
    name: "EditImageForm",
    components: {
    },
    props: {
        showForm: {
            type: Boolean,
            required: true
        },
        image: {
            type: Object as PropType<Image>,
            required: true
        },
        users: {
            type: Object as PropType<User[]>
        }
    },
    emits: ['update:showForm', 'submitted'],
    computed: {
        show: {
            get() {
                return this.showForm
            },
            set(value: boolean) {
                this.$emit('update:showForm', value)
            }
        },
        imageUri() {
            return this.editableImage.uri + '?' + this.token
        }
    },
    data() {
        return {
            editableImage: emptyImage() as Image,
            loading: false as Boolean,
            error: "" as String,
            token: ""
        }
    },
    watch: {
        showForm: function() {
            this.error = ""
            this.editableImage = JSON.parse(JSON.stringify(this.image))    
        }
    },
    mounted() {
        this.token = this.$store.getters.getSasToken
        this.editableImage = JSON.parse(JSON.stringify(this.image))
    },
    methods: {
        async updateImage() {
            this.error = ""
            this.loading = true
            const newImage = await $api.images.update(this.editableImage)
            this.loading = false
            if (newImage) {
                this.show = false
                this.$emit('submitted')
            }
            else {
                this.error = "Der opstod en fejl..."
            }
        }
    }
});
