
import { PropType, defineComponent } from 'vue';
import EditImageForm from './EditImageForm.vue';
import ConfirmModal from '../ConfirmModal.vue';
import { emptyImage } from '@/common/utils';
import { Image } from '@/Models/Image';
import { $api } from '@/common/apiService'
import { User } from '@/Models/User';

export default defineComponent ({
    name: "ImageTable",
    components: {
        EditImageForm,
        ConfirmModal
},
    props: {
        images: {
            type: Object as PropType<Image[]>,
            required: true
        },
        loadingImages: {
            type: Boolean,
            required: true
        },
        users: {
            type: Object as PropType<User[]>,
            required: true
        }
    },
    computed: {
        loading: {
            get(): boolean {
                return this.loadingImages
            },
            set(value: boolean) {
                this.$emit('update:loadingImages', value)
            }
        },
        years() {
            return this.images.map(image => image.year).filter((value, index, array) => array.indexOf(value) === index)
        }
    },
    data() {
        return {
            error: "",
            token: "",
            ImagesFields: [
                {
                    key: 'year',
                    label: 'År'
                },
                {
                    key: 'actions',
                    label: 'Handlinger'
                }
            ],
            imageToBeDeleted: emptyImage(),
            showConfirmDeleteModal: false,
            changedImage: emptyImage(),
            showEditImageModal: false
        }
    },
    mounted() {
        this.token = this.$store.getters.getSasToken
    },
    methods: {
        confirmDeleteImage(image: Image) {
            this.imageToBeDeleted = image
            this.showConfirmDeleteModal = true
        },
        async deleteImage() {
            this.error = ""
            this.loading = true
            const response = await $api.images.delete(this.imageToBeDeleted)
            if (response === null) {
                this.error = "Der skete en fejl på serveren. Prøv igen."
                this.loading = false
            }
            else {
                this.$emit('refresh')
            }
        },
        imagesByYear(year: number) {
            return this.images.filter(image => image.year === year)
        },
        editImage(image: Image) {
            this.error = ""
            this.changedImage = image
            this.showEditImageModal = true
        }
    },
});
