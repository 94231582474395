
import { defineComponent } from 'vue';
import { $api } from '../common/apiService';

export default defineComponent ({
    name: "ChangePasswordCard",
    components: {
    },
    data () {
        return {
            password1: "",
            password2: "",
            errorMessage: "",
            emptyFields: false
        }
    },
    methods: {
        async changePassword() {
            this.errorMessage = ""
            this.emptyFields = false
            if (!this.validatePassword()) {
                return
            }
            let user = await $api.users.changePassword(this.password1)
            await this.$store.dispatch('updateUser', user)
            this.$emit('continue')
        },
        validatePassword() {
            if (this.password1 === "" || this.password2 === "") {
                this.emptyFields = true
                return false
            }
            else return this.password1 === this.password2
        }
    }
});

