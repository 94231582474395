import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b2a00f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "image-showcase"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    "model-value": _ctx.showCarousel,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showCarousel) = $event)),
    size: "xl",
    centered: "",
    "hide-header": "",
    "hide-footer": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_button, {
        class: "close-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCarousel = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("X")
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "image-container",
          key: idx
        }, [
          (idx === _ctx.index)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (!_ctx.first)
                  ? (_openBlock(), _createBlock(_component_b_button, {
                      key: 0,
                      class: "navigation-button previous-button",
                      variant: "primary",
                      onClick: _ctx.decrementIndex
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(`< `))
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.last)
                  ? (_openBlock(), _createBlock(_component_b_button, {
                      key: 1,
                      class: "navigation-button next-button",
                      variant: "primary",
                      onClick: _ctx.incrementIndex
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(`>`))
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createElementVNode("img", {
                  src: image.uri + '?' + _ctx.token
                }, null, 8, _hoisted_2)
              ]))
            : _createCommentVNode("", true),
          (idx === _ctx.index && _ctx.images[_ctx.index]?.description)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.images[_ctx.index].description), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["model-value"]))
}