import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39bb5014"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "form-content"
}
const _hoisted_2 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_3 = {
  key: 2,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_img = _resolveComponent("b-img")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_offcanvas = _resolveComponent("b-offcanvas")!

  return (_openBlock(), _createBlock(_component_b_offcanvas, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
    title: `Upload billede for ${_ctx.year}`
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_b_spinner, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_b_form, { onSubmit: _ctx.submitImage }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button, { onclick: "document.getElementById('photo-upload').click()" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Vælg billede")
                  ]),
                  _: 1
                }),
                _createElementVNode("input", {
                  type: "file",
                  accept: ".png,.jpg,.jpeg",
                  id: "photo-upload",
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setUploadedImage && _ctx.setUploadedImage(...args))),
                  style: {"display":"none"},
                  tabindex: "-1"
                }, null, 32),
                (_ctx.imageFile)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_b_img, {
                        class: "upload-preview",
                        src: _ctx.getPreview()
                      }, null, 8, ["src"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.imageFile)
                  ? (_openBlock(), _createBlock(_component_b_form_group, {
                      key: 1,
                      label: "Beskrivelse (valgfri):",
                      class: "image-description"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_textarea, {
                          modelValue: _ctx.description,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
                          placeholder: "Hvad er der på billedet?"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.error)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.error), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_b_button, {
                  class: "submit-upload-button",
                  size: "lg",
                  type: "submit",
                  variant: "success",
                  disabled: !_ctx.imageFile
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Upload")
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ]))
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}