
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HeaderNavigation',
  methods: {
    async logOut() {
      await this.$store.dispatch('logOut')
      location.reload()
    },
    goToMainPage() {
      this.$router.push("/")
    },
    goToAdminPage() {
      this.$router.push("/admin")
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.isAuthenticated
    },
    adminAccess() {
      return this.$store.getters.isAdmin
    }
  }
});
