
import { defineComponent } from 'vue';
import texts from '../assets/Text.json'
import { $api } from '../common/apiService'
import TripTile from '@/components/TripTile.vue'
import ContentCard from '@/components/ContentCard.vue';
import { Trip } from '@/Models/Trip';


export default defineComponent ({
    name: "HomePage",
    components: {
        TripTile,
        ContentCard
    },
    data () {
        return {
            texts: texts,
            nextTrip: null as Trip | null,
            previousTrips: null as Trip[] | null,
        }
    },
    async mounted() {
        const trips = await $api.trips.fetch();
        if (trips && trips.length > 0) {
            this.nextTrip = trips.shift() ?? null;
            this.previousTrips = trips;
        }
    }
});

