
import { User } from '@/Models/User';
import { defineComponent } from 'vue';
import { $api } from '@/common/apiService'
import UserTable from '@/components/Admin/UserTable.vue';
import TripTable from '@/components/Admin/TripTable.vue';
import { Trip } from '@/Models/Trip';
import ImageTable from '@/components/Admin/ImageTable.vue';
import { Image } from '@/Models/Image';
import AdminUtilities from '@/components/Admin/AdminUtilities.vue';

export default defineComponent ({
    name: "AdminPage",
    components: {
    UserTable,
    TripTable,
    ImageTable,
    AdminUtilities
},
    data() {
        return {
            users: [] as User[],
            loadingUsers: false,
            trips: [] as Trip[],
            loadingTrips: false,
            images: [] as Image[],
            loadingImages: false
        }
    },
    methods: {
        async fetchUsers() {
            this.loadingUsers = true
            const users = await $api.users.fetch()
            if (users) {
                this.users = users
            }
            this.loadingUsers = false
        },
        async fetchTrips() {
            this.loadingTrips = true
            const trips = await $api.trips.fetch()
            if (trips) {
                this.trips = trips
            }
            this.loadingTrips = false
        },
        async fetchImages() {
            this.loadingImages = true
            const images = await $api.images.fetch()
            if (images) {
                this.images = images
            }
            this.loadingImages = false
        }
    },
    async mounted() {
        await this.fetchUsers()
        await this.fetchTrips()
        await this.fetchImages()
    }
});

