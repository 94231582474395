
import { defineComponent } from 'vue';
const isoDateFormat = /^\d{4}-\d{2}-\d{2}/;

const danishMonths = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December']

export default defineComponent({
  name: 'CalendarCard',
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  methods: {
    isIsoDateString(value: any): boolean {
      return value && typeof value === "string" && isoDateFormat.test(value);
    },
    getStartDate() {
      if (this.isIsoDateString(this.startDate)) {
        const date = new Date(this.startDate)
        return date.getDate()
      }
    },
    getStartMonth() {
      if (this.isIsoDateString(this.startDate)) {
        const date = new Date(this.startDate)
        return danishMonths[date.getMonth()]
      }
    },
    getEndDate() {
      if (this.isIsoDateString(this.endDate)) {
        const date = new Date(this.endDate)
        return date.getDate()
      }
    },
    getEndMonth() {
      if (this.isIsoDateString(this.endDate)) {
        const date = new Date(this.endDate)
        return danishMonths[date.getMonth()]
      }
    }
  }
})


